export const Role = {
    Consumer: "CONSUMER",
    Technician: "TECHNICIAN",
    Support: "SUPPORT",
    Developer: "DEVELOPER",
    Admin: "ADMIN",
    Manufacturer: "MANUFACTURER",
}

export const Roles = Object.values(Role)
