import { useState } from "react"
import { Col, Dropdown, Row, Form, Button } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../../components/MessageReporter"

export const Manufacturing = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const products = ["BEN", "TH400", "TH230"]
    const noProduct = "Select product"
    const [selectedProduct, setSelectedProduct] = useState(noProduct)
    const [email, setEmail] = useState("")
    const [outputDir, setOutputDir] = useState("/")
    const [batchId, setBatchId] = useState(undefined)
    const [sns, setSns] = useState("")

    const createProducts = () => {
        setBatchId(undefined)

        const splitSns = sns.split(/(?:,| |\n)+/)
        setSns(splitSns.join("\n"))
        const serialNumbers = splitSns.map((sn) => sn.trim())

        const body = {
            device_type: selectedProduct,
            notification_email: email,
            output_dir: outputDir,
            serial_numbers: serialNumbers,
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post("/v2/manufacturer/products/create", body, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setBatchId(data.batch_id)
                setSns("")
            })
            .catch((e) => {
                addMessage(
                    "Failed to create productdata",
                    "Something went wrong while creating productdata, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }
    return (
        <>
            <Row className="content-section">
                <Col>
                    <h2 className="mb-3">Create product data</h2>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedProduct}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {products.map((p) => {
                                return (
                                    <Dropdown.Item
                                        key={p}
                                        onClick={(e) => {
                                            setSelectedProduct(e.target.innerText)
                                        }}
                                    >
                                        {p}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr />

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Notification email address (receives an email when productdata creation
                            has finished)
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Output directory on FTP server</Form.Label>
                        <Form.Control
                            type="text"
                            value={outputDir}
                            onChange={(e) => setOutputDir(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Specify serial number(s) (newline or comma separated)
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={sns}
                            onChange={(e) => setSns(e.target.value)}
                        />
                    </Form.Group>

                    {batchId !== undefined && (
                        <Form.Label className="text-success">
                            Productdata batch created with ID: {batchId}. You will receive an email
                            when all productdata is available on your server.
                        </Form.Label>
                    )}
                    <Button
                        className="mt-4 link"
                        disabled={
                            selectedProduct === noProduct ||
                            email === "" ||
                            outputDir === "" ||
                            sns === ""
                        }
                        onClick={createProducts}
                        type="button"
                    >
                        Create productdata
                    </Button>
                </Col>
            </Row>
        </>
    )
}
