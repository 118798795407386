import React from "react"
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom"
import { Layout } from "./pages/Layout"
import {
    Auth0ErrorPage,
    EmailNotVerifiedErrorPage,
    RouterErrorPage,
} from "./components/ErrorBoundaries/ErrorPages"
import { DeviceOverview } from "./pages/Devices/DeviceOverview"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { DeviceDetail } from "./pages/Devices/DeviceDetail"
import { FirmwareOverview } from "./pages/Firmware/FirmwareOverview"
import { FirmwareDetail } from "./pages/Firmware/FirmwareDetail"
import { AddFirmware } from "./pages/Firmware/AddFirmware"
import { DeviceGroupOverview } from "./pages/DeviceGroups/DeviceGroupOverview"
import { AddDeviceGroup } from "./pages/DeviceGroups/AddDeviceGroup"
import { DeviceGroupDetail } from "./pages/DeviceGroups/DeviceGroupDetail"
import { Callback } from "./pages/Callback"
import "./assets/main.scss"
import { UserOverview } from "./pages/Users/UserOverview"
import { UserDetail } from "./pages/Users/UserDetail"
import { UserAdd } from "./pages/Users/UserAdd"
import { MessageReporterProvider } from "./components/MessageReporter"
import { RoleContextProvider } from "./components/RoleContext"
import { SystemOverview } from "./pages/Systems/SystemOverview"
import { DeviceOverviewRemoteLogging } from "./pages/Devices/DeviceOverviewRemoteLogging"
import { SystemDetail } from "./pages/Systems/SystemDetail"
import { NotFound } from "./pages/NotFound"
import { Home } from "./pages/Home/Home"
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    BarElement,
} from "chart.js"
import Zoom from "chartjs-plugin-zoom"
import annotationPlugin from "chartjs-plugin-annotation"
import { CreateAccount } from "./pages/CreateAccount"
import { Manufacturing } from "./pages/Manufacturing/Manufacturing"

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    TimeScale,
    Zoom,
    annotationPlugin
)

export const App = () => {
    const router = createBrowserRouter([
        {
            path: "create-account",
            element: <CreateAccount />,
        },
        {
            path: "/",
            element: <AuthWrapper />,
            errorElement: <RouterErrorPage />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/device",
                    element: <DeviceOverview />,
                },
                {
                    path: "/remotelogging",
                    element: <DeviceOverviewRemoteLogging />,
                },
                {
                    path: "/device/:id",
                    element: <DeviceDetail />,
                },
                {
                    path: "/devicegroup",
                    element: <DeviceGroupOverview />,
                },
                {
                    path: "/devicegroup/:id",
                    element: <DeviceGroupDetail />,
                },
                {
                    path: "/devicegroup/add",
                    element: <AddDeviceGroup />,
                },
                {
                    path: "/firmware",
                    element: <FirmwareOverview />,
                },
                {
                    path: "/firmware/:id",
                    element: <FirmwareDetail />,
                },
                {
                    path: "/firmware/add",
                    element: <AddFirmware />,
                },
                {
                    path: "/user",
                    element: <UserOverview />,
                },
                {
                    path: "/user/add",
                    element: <UserAdd />,
                },
                {
                    path: "/user/:id",
                    element: <UserDetail />,
                },
                {
                    path: "/callback",
                    element: <Callback />,
                },
                {
                    path: "/system",
                    element: <SystemOverview />,
                },
                {
                    path: "/system/:id",
                    element: <SystemDetail />,
                },
                {
                    path: "/manufacturing",
                    element: <Manufacturing />,
                },
                {
                    path: "*",
                    element: <NotFound />,
                },
            ],
        },
    ])

    return <RouterProvider router={router} />
}

const AuthWrapper = () => {
    const navigate = useNavigate()
    const redirectCallback = (appState) =>
        navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname)

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            onRedirectCallback={redirectCallback}
            useRefreshTokens={true}
            authorizationParams={{
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                redirect_uri: window.location.origin + "/callback",
                scope: "openid profile email offline_access",
            }}
        >
            <Providers />
        </Auth0Provider>
    )
}

const emailNotVerifiedYetError = "Verify your email before logging in."

const Providers = () => {
    // Handle Auth0 errors and prevent infinite loading loop.
    const { error } = useAuth0()
    if (error?.message === emailNotVerifiedYetError) {
        return <EmailNotVerifiedErrorPage error={error} />
    }
    if (error) {
        return <Auth0ErrorPage error={error} />
    }

    return (
        <MessageReporterProvider>
            <RoleContextProvider>
                <Layout />
            </RoleContextProvider>
        </MessageReporterProvider>
    )
}
